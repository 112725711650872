import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import Button from "react-bootstrap/Button";
import CameraForm from "./CameraForm";
import StreamViewer from "./StreamViewer";

const API_URL = "https://u5tx7hitwj.execute-api.ap-southeast-1.amazonaws.com/prod/cameras/";

const CameraDashboard = ({ idToken }) => {
  const [cameras, setCameras] = useState([]);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [currentCamera, setCurrentCamera] = useState(null);

  useEffect(() => {
    fetchCameras();
  }, []);

  const fetchCameras = async () => {
    try {
      const response = await fetch(API_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify({ type: "list", ExcludeState: "DELETED" }),
      });
      const data = await response.json();
      setCameras(data || []); // Ensure cameras is always an array
    } catch (error) {
      console.error("Error fetching cameras:", error);
    }
  };

  const handleToggleStartStop = async (camera) => {
    const action = camera.State === "STOPPED" ? "start" : "stop";
    try {
      await fetch(API_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify({ type: action, CameraId: camera.CameraId }),
      });
      fetchCameras(); // Refresh the list after toggling
    } catch (error) {
      console.error(`Error performing ${action} action:`, error);
    }
  };

  const handleAdd = () => {
    setCurrentCamera(null); // Clear current camera for new addition
    setIsFormOpen(true);
  };

  const handleEdit = (camera) => {
    setCurrentCamera(camera); // Pass camera details to the form
    setIsFormOpen(true);
  };

  const handleDelete = async (cameraId) => {
    try {
      await fetch(API_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify({ type: "delete", CameraId: cameraId }),
      });
      fetchCameras(); // Refresh the list after deletion
    } catch (error) {
      console.error("Error deleting camera:", error);
    }
  };

  const handleFormSubmit = () => {
    fetchCameras(); // Refresh the list after add/edit
    setIsFormOpen(false);
  };

  return (
    <div style={{ height: "100vh", overflowY: "auto", padding: "20px", boxSizing: "border-box" }}>
      <h2>Camera Dashboard</h2>
      <Button variant="success" style={{ marginBottom: "20px" }} onClick={handleAdd}>
        Add Camera
      </Button>

      <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        {cameras
          .filter((camera) => camera) // Ensure only valid camera entries
          .map((camera) => (
            <div
              key={camera.CameraId}
              style={{
                display: "flex",
                gap: "20px",
                border: "1px solid #ddd",
                borderRadius: "8px",
                padding: "10px",
              }}
            >
              {/* Left Column: Camera Details and Actions */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  gap: "10px",
                  minWidth: "200px",
                }}
              >
                {/* Camera Location */}
                <div
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  Camera Location:
                </div>
                <div
                  style={{
                    wordWrap: "break-word", // Wrap long text
                    wordBreak: "break-word", // Break words if too long
                    overflowWrap: "break-word", // Support for long strings
                  }}
                >
                  {camera.Tags?.Location || "Unknown"}
                </div>

                {/* Start/Stop Button */}
                <Button
                  variant={camera.State === "STOPPED" ? "success" : "warning"}
                  onClick={() => handleToggleStartStop(camera)}
                >
                  {camera.State === "STOPPED" ? "Start" : "Stop"}
                </Button>

                {/* Edit Button */}
                <Button variant="primary" onClick={() => handleEdit(camera)}>
                  Edit
                </Button>

                {/* Delete Button */}
                <Button variant="danger" onClick={() => handleDelete(camera.CameraId)}>
                  Delete
                </Button>
              </div>

              {/* Right Column: Stream Viewer or Status Message */}
              <div style={{ flexGrow: 1 }}>
                {camera.State === "STARTED" ? (
                  <StreamViewer
                    idToken={idToken}
                    bucketName="home-lz-2-s3-streaming-bucket"
                    cloudFrontDomain="d2dxr8dfk79gun.cloudfront.net"
                    playlistKey={`${camera.CameraId}/index.m3u8`}
                  />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                      backgroundColor: "#f8f9fa",
                      border: "1px solid #ddd",
                      borderRadius: "8px",
                      padding: "20px",
                      textAlign: "center",
                    }}
                  >
                    <p style={{ margin: 0, fontWeight: "bold" }}>
                      Camera is stopped, no video feed
                    </p>
                  </div>
                )}
              </div>
            </div>
          ))}
      </div>

      <Modal
        isOpen={isFormOpen}
        onRequestClose={() => setIsFormOpen(false)}
        contentLabel="Camera Form Modal"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "400px",
          },
        }}
      >
        <CameraForm
          idToken={idToken}
          camera={currentCamera}
          onClose={() => setIsFormOpen(false)}
          onSubmit={handleFormSubmit}
          API_URL={API_URL}
        />
      </Modal>
    </div>
  );
};

export default CameraDashboard;
