import React, { useEffect, useRef } from "react";
import Hls from "hls.js";

const StreamViewer = ({ idToken, bucketName, playlistKey, cloudFrontDomain, cameraLocation }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const initializeStream = async () => {
      if (!idToken || !bucketName || !playlistKey || !cloudFrontDomain) {
        console.error("Missing required parameters for stream initialization.");
        return;
      }

      try {
        // Generate a timestamp for cache busting
        const timestamp = new Date().getTime();
        const cloudFrontUrl = `https://${cloudFrontDomain}/${playlistKey}?timestamp=${timestamp}`;

        // Initialize HLS.js for playback
        if (Hls.isSupported() && videoRef.current) {
          const hls = new Hls({ debug: true }); // Enable debug mode
          hls.loadSource(cloudFrontUrl);
          hls.attachMedia(videoRef.current);

          hls.on(Hls.Events.MANIFEST_PARSED, () => {
            videoRef.current.play().catch((err) => {
              console.error("Autoplay failed:", err);
            });
          });
        } else if (videoRef.current.canPlayType("application/vnd.apple.mpegurl")) {
          // Fallback for Safari
          videoRef.current.src = cloudFrontUrl;
          videoRef.current.addEventListener("loadedmetadata", () => {
            videoRef.current.play().catch((err) => {
              console.error("Autoplay failed:", err);
            });
          });
        }
      } catch (error) {
        console.error("Error initializing stream:", error);
      }
    };

    initializeStream();
  }, [idToken, bucketName, playlistKey, cloudFrontDomain]);

  return (
    <div>
      {/* Reintroduced Camera Location */}
      {cameraLocation && (
        <h2 style={{ marginBottom: "10px" }}>Camera Location: {cameraLocation}</h2>
      )}
      <video
        ref={videoRef}
        muted
        autoPlay
        playsInline
        style={{ width: "100%", height: "auto", maxWidth: "800px", borderRadius: "8px" }}
      />
    </div>
  );
};

export default StreamViewer;
