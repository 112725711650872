import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { Authenticator, View, Image } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import logo from './family.png';
import { Helmet } from 'react-helmet';
import { getCurrentUser, fetchAuthSession, fetchUserAttributes } from 'aws-amplify/auth';
import CameraDashboard from './components/cameraDashboard';
import StreamViewer from './components/StreamViewer';

async function currentdAuthenticatedUser() {
  try {
    const { username, userId, signInDetails } = await getCurrentUser();
    console.log(`The username: ${username}`);
    console.log(`The userId: ${userId}`);
    console.log(`The signInDetails: ${signInDetails}`);
  } catch (err) {
    console.log(err);
  }
} 

// Dashboard Component

function Dashboard({ signOut }) {
  const [userAttributes, setUserAttributes] = useState(null);
  const [idToken, setIdToken] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [cameras, setCameras] = useState([]);

  // Fetch user data and cameras
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const user = await getCurrentUser();
        const attributes = await fetchUserAttributes(user);
        console.log("Fetched User Attributes:", attributes);
        setUserAttributes(attributes);
        const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
        console.log(`idToken is ${idToken}`);
        console.log(`accessToken is ${accessToken}`);
        setAccessToken(accessToken);
        setIdToken(idToken);
      } catch (error) {
        console.error("Error fetching user attributes:", error);
      }
    };

    fetchUserData();
  }, []);

  // Fetch cameras with status "STARTED"
  useEffect(() => {
    const fetchCameras = async () => {
      try {
        const response = await fetch(
          "https://u5tx7hitwj.execute-api.ap-southeast-1.amazonaws.com/prod/cameras/",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${idToken}`,
            },
            body: JSON.stringify({ type: "list", ExcludeState: "DELETED" }),
          }
        );
        const data = await response.json();
        // Filter cameras that are "STARTED"
        setCameras(data.filter((camera) => camera.State === "STARTED"));
      } catch (error) {
        console.error("Error fetching cameras:", error);
      }
    };

    if (idToken) {
      fetchCameras();
    }
  }, [idToken]);

  if (!userAttributes) {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p>Loading user data...</p>
        <button onClick={signOut}>Sign Out</button>
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        height: "100vh", // Full viewport height
        overflow: "hidden", // Prevent extra scrollbars
        padding: "20px",
        boxSizing: "border-box",
      }}
    >
      <h1>
        Welcome, {userAttributes.given_name || "User"} {userAttributes.family_name || ""}!
      </h1>
      <p>You are logged in as {userAttributes.email || "unknown email"}</p>
      <button onClick={signOut} style={{ padding: "10px 20px", marginBottom: "20px" }}>
        Sign Out
      </button>

      {/* Camera Dashboard */}
      <div
        style={{
          flex: "1 1 auto", // Ensure the dashboard takes up remaining space
          width: "100%",
          overflowY: "auto", // Allow scrolling for overflowing content
          border: "1px solid #ddd",
          borderRadius: "8px",
        }}
      >
        <CameraDashboard idToken={idToken} />
      </div>

      {/* Render StreamViewer only for cameras with State "STARTED" */}

    </div>
  );
}


const containerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
}

function App() {
  return (
    <div>
      <Helmet>
        <title>Foohouse</title>
      </Helmet>
      <div style={containerStyle}>
        <Router>
          <Authenticator 
            hideSignUp={true}
            signUpAttributes={['email', 'given_name', 'family_name', 'gender']}
            components={{
              SignIn: {
                Header: () => (
                  <View textAlign="center" padding="1px" marginTop="50px">
                    <Image
                      src={logo} 
                      alt="Logo"
                      style={{ maxWidth: '150px' }}
                    />
                    <h2 style={{ marginTop: '10px', color: '#333' }}>Welcome to Foohouse!</h2>
                  </View>
                )
              },
              SignUp: {
                Header: () => (
                  <View textAlign="center" padding="1px">
                    <Image
                      src={logo} 
                      alt="Logo"
                      style={{ maxWidth: '150px' }}
                    />
                    <h2 style={{ marginTop: '10px', color: '#333' }}>Join Foohouse today!</h2>
                  </View>
                )
              }
            }}
          >
            {({ signOut, user }) => (
              <Routes>
                {/* Redirect authenticated users to dashboard */}
                <Route path="/" element={<Navigate to="/dashboard" replace />} />
                <Route
                  path="/dashboard"
                  element={<Dashboard user={user} signOut={signOut} />}
                />
                {/* Fallback or home page */}
                <Route path="/home" element={<Dashboard user={user} signOut={signOut} />} />
              </Routes>
            )}
          </Authenticator>
        </Router>
      </div>
    </div>
  );
}

export default App;
